











import Vue from 'vue';
import SearchInput from './SearchInput.vue';
import ModalFade from '~/components/ModalFade.vue';
import { isHealthPath } from '~/router';

export default Vue.extend({
  name: 'TopHeaderSearchActionsheet',

  components: {
    SearchInput,
    ModalFade,
  },

  computed: {
    // !86c04jx42 поменять цвет  #e85495 на #2962f9
    cssVariables (): string {
      return `
         --main-color-search-mobile: ${isHealthPath(this.$route.path) ? ' #e85495' : '#ed5e42'}
      `;
    },
  },

  methods: {
    handleSearch () {
      this.closeModal();
    },
    closeModal () {
      this.$modal.hideModal('windows', 'TopHeaderSearchActionsheet');
    },
  },
});
